<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">新生动物</h3>
    <div class="detail">
      <el-table
          :data="tableData" border
          class="tableMember"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
      >
        <el-table-column label="笼号" prop="cageNo" show-overflow-tooltip></el-table-column>
        <el-table-column label="笼架号" prop="frameNo" show-overflow-tooltip></el-table-column>
        <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">{{ scope.row.gender == 0 ? 'M' : 'F' }}</template>
        </el-table-column>
        <el-table-column label="出生日期" width="110">
          <template slot-scope="scope">{{ scope.row.birthDate | formatDay }}</template>
        </el-table-column>
        <el-table-column label="基因类型" class-name="addClass">
          <el-table-column v-for="(obj,index) in typeList" :key="index"
                           :label="obj.title"
                           min-width="110">
            <template slot-scope="scope">
              <template v-if="scope.row.show">
                <el-input size="small" placeholder="基因类型" v-model="scope.row.typeList[index].type"></el-input>
              </template>
              <span v-else>{{ scope.row.typeList[index].type }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="父本" prop="foverbit" show-overflow-tooltip></el-table-column>
        <el-table-column label="母本" prop="moverbit" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="100" fixed="right" v-if="showAction">
          <template slot-scope="scope">
            <div class="editColor">
              <el-button type="text" v-if="!scope.row.show " @click="edit(scope.row,scope.$index)">修改
              </el-button>
              <el-button type="text" v-else @click="save(scope.row,scope.$index)">保存</el-button>
              <el-button type="text" @click="deleteA(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
      <div class="fromSave" v-if="showAction">
        <el-button type="primary" :disabled="disabled" size="small" @click="recordTable(1)">记录完成</el-button>
        <el-button type="primary" :disabled="disabled" plain size="small" @click="recordTable(0)">保存</el-button>
        <el-button type="primary" plain size="small" @click="allBtnEdit">批量编辑</el-button>
        <el-button type="primary" plain size="small" @click="allBtn">批量确认</el-button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "newAnimal",
  props: ['id'],
  inject: ['reload'],
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 21,
      },
      typeList: [],
      showAction: true,
      disabled: false
    }
  },
  mounted() {
    this.getAnimal()
  },
  methods: {
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getAnimal();
    },
    // 获取新生小鼠
    getAnimal() {
      let that = this
      let params = {
        pageSize: that.page.size,
        pageNum: that.page.current,
        type: 1
      }
      that.$get("/mate/baby/byPage/" + this.id, params).then((res) => {
        if (res.status == 200) {
          // 基因类型
          let arr = res.data.geneType.split(',')
          arr.forEach(item => {
            this.typeList.push({
              title: item
            })
          })
          // 新生小鼠数据表格
          res.data.MateBabyList.records.forEach(item => {
            item['typeList'] = []
            if (item.geneType == null) {
              item.show = true
              this.typeList.forEach(obj => {
                item.typeList.push({type: "", title: obj.title})
              })
            } else {
              item.show = false
              item.typeList = JSON.parse(item.geneType)
            }

            if (item.state == 1) {
              this.showAction = false
              this.disabled = true
            }
          })
          this.tableData = res.data.MateBabyList.records;

          this.page.current = res.data.MateBabyList.current;
          this.page.total = res.data.MateBabyList.total;
          this.page.size = res.data.MateBabyList.size;
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    // 编辑
    edit(row, index) {
      row.show = !row.show
      this.$set(this.tableData, index, row)
    },
    // 保存基因类型
    save(row, index) {
      row.geneType = JSON.stringify(row.typeList)
      this.$putJson('/mate/baby', row).then(res => {
        if (res.status == 200) {
          row.show = false
        }
      }).catch(error => {
        this.$message.error(error)
      })
    },
    // 删除
    deleteA(id) {
      this.$del('/mate/baby/' + id).then(res => {
        if (res.status == 200) {
          this.$message.success("删除成功")
          this.$nextTick(() => {
            this.getAnimal()
          })
        }
      })
    },
    allBtnEdit() {
      this.tableData.map((i, index) => {
        i.show = true
        this.$set(this.tableData, index, i)
      })
    },
    allBtn() {
      this.tableData.forEach(item => {
        item.geneType = JSON.stringify(item.typeList)
      })
      this.$putJson('/mate/baby/batch', this.tableData).then(res => {
        if (res.status == 200) {
          this.$message.success("保存成功")
          this.reload();
        }
      })
    },
    // 提交数据，保存
    recordTable(state) {
      this.$put('/mate/baby/state/' + this.id, {state: state}).then(res => {
        if (res.status == 200) {
          this.$message.success("提交成功")
          // this.back(-1)
          if(state===1){
            this.$emit('changeActive')
          }else {
            this.$router.go(-1)
          }
        }else {
          this.$message.error(error)
        }
      }).catch(error => {
        this.$message.error(error)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.detail {
  .tableMember {
    margin-left: 0;
    margin-bottom: 16px;
  }

  .pagination {
    margin-bottom: 16px;
  }
}
</style>
